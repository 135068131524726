import React from 'react';
import { Container, Card, ListGroup, Button, Row, Col, Carousel } from 'react-bootstrap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import avatar_motobombas from "../images/avatar_motobombas.png";
import b_bombas_sumergibles_pozos from "../images/b_bombas_sumergibles_pozos.png";
import bsdp_serie_95pr_panelli from "../images/bsdp_serie_95pr_panelli.png";
import bsdp_serie_95pr_panelli_cut from "../images/bsdp_serie_95pr_panelli_cut.png";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { NavLink } from "react-router-dom";

const Banner = () => {
  return (
    <Card style={{ width: 'w-100', backgroundColor: '#2D4E76' }}>
      <Card.Img variant="top" src={b_bombas_sumergibles_pozos} />
    </Card>
  );
};

const NewContainer = () => {
  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = require('../descargables/catalogo_95pr_panelli.pdf');
    link.download = 'catalogo_95pr_panelli.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Container fluid style={{ backgroundColor: '#FFFFFF' }}>
      <Row className="justify-content-center mt-4">
        <Col xs={12} md={2} className="mb-4">
          <Card border="light">
            <Carousel>
              <Carousel.Item>
                <img className="d-block w-100" src={bsdp_serie_95pr_panelli} alt="bsdp_serie_95pr_panelli" />
              </Carousel.Item>
              <Carousel.Item>
                <img className="d-block w-100" src={bsdp_serie_95pr_panelli_cut} alt="bsdp_serie_95pr_panelli_cut" />
              </Carousel.Item>
            </Carousel>
          </Card>
        </Col>
        <Col xs={12} md={3} className="mb-4">
          <Card border="light">
            <Card.Body>
              <Card.Title>Serie 95PR Panelli</Card.Title>
              <Card.Text>
                Las electrobombas sumergibles serie 95PR son las más pequeñas de la extensa gama de fabricación de más de 40 años de Panelli.
                El concepto innovador del impulsor favorece el flujo de agua en la bomba con importantes ventajas hidráulicas y de funcionamiento.
                Las electrobombas de la serie 95PR se destacan por su elevada eficiencia y resistencia, lo que las convierte en soluciones de alto rendimiento 
                y confiabilidad. Además, su impulsor flotante está diseñado para una durabilidad superior, lo cual, sumado a su bajo requerimiento de 
                mantenimiento, garantiza una operación prolongada con costos mínimos. Los componentes homologados por la F.D.A. aseguran estándares de 
                calidad y seguridad en aplicaciones que requieren un cuidado especial en el manejo de agua.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={3} className="mb-4">
          <Card border="light">
            <Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item><Card.Title>Materiales</Card.Title></ListGroup.Item>
                <ListGroup.Item>Impulsor en Lexan.</ListGroup.Item>
                <ListGroup.Item>Difusor en Noryl GFN2.</ListGroup.Item>
                <ListGroup.Item>Elemento intermedio en acero inoxidable AISI 304.</ListGroup.Item>
                <ListGroup.Item>Camisa externa en acero inoxidable AISI 304.</ListGroup.Item>
                <ListGroup.Item>Cuerpo de aspiración e impulsión en bronce o acero inoxidable.</ListGroup.Item>
                <ListGroup.Item>Válvula de retención en acero inoxidable AISI 304 incorporada directamente en la bomba.</ListGroup.Item>
                <ListGroup.Item><Card.Title>Prestaciones hidráulicas</Card.Title></ListGroup.Item>
                <ListGroup.Item>Caudal hasta 33m3/h.</ListGroup.Item>
                <ListGroup.Item>Altura hasta 500m.</ListGroup.Item>
                <ListGroup.Item>Cantidad de arena hasta 300g/m3.</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={2} className="mb-4">
          <Card border="light">
            <Card.Body>
              <Card.Title>Descargables</Card.Title>
              <Card.Text>
                <Button variant="light" className="m-2" onClick={handleDownload}>
                  <NavLink href='#'>
                    <PictureAsPdfIcon fontSize='large' color='primary' />
                  </NavLink>
                </Button>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Contacto = () => {
  const buttonStyle = {
    backgroundColor: '#2D4E76',
    borderColor: '#2D4E76', // Color del borde
  };

  return (
    <Container className="d-flex justify-content-center" style={{ marginTop: "auto" }}>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://wa.me/584244024689" target="_blank" rel="noopener noreferrer">
          <WhatsAppIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://instagram.com/motobombasca?igshid=ZDc4ODBmNjlmNQ==" target="_blank" rel="noopener noreferrer">
          <InstagramIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://www.google.com/maps/place/Motobombas,+C.A.+Sede+Principal/@10.1984189,-67.9175419,17z/data=!3m1!4b1!4m6!3m5!1s0x8e80679603cd1def:0x897bd1f33654eda4!8m2!3d10.1984189!4d-67.9175419!16s%2Fg%2F11d_9hj8zl" target="_blank" rel="noopener noreferrer">
          <FmdGoodIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
    </Container>
  );
};

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: '#2D4E76',
        color: "#FAFAFA",
        padding: "20px",
        textAlign: "left"
      }}
    >
      <Container>
        <Row>
          <Col md={3}>
            <h5>MENÚ</h5>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li><NavLink to="/bombas" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Bombas</NavLink></li>
              <li><NavLink to="/cables" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Cables</NavLink></li>
              <li><NavLink to="/componentes" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Componentes</NavLink></li>
              <li><NavLink to="/motores" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Motores</NavLink></li>
              <li><NavLink to="/tubos" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Tubos</NavLink></li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>ENLACES</h5>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li><NavLink to="/aboutus" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Sobre Nosotros</NavLink></li>
              <li>
                <a href="https://wa.me/584244024689" style={{ color: "#FAFAFA", textDecoration: 'none' }}>
                  Solicitar presupuesto
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>CONTACTÁNOS</h5>
            <p>Motobombas, C.A.<br />
              C. 93, Paraparal 2011, Carabobo,<br />
              República Bolivariana de Venezuela<br />
              Email: <a href="mailto:motoredes2021@gmail.com" style={{ color: "#FAFAFA" }}>motoredes2021@gmail.com</a><br />
              Teléfono: +58-245-4154042
            </p>
          </Col>
          <Col md={3}>
            <h5>SUCURSALES</h5>
            <p>Sucursal Oriente Teléfono: +58-283-4002563</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Contacto />
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <p>© 2023 Motobombas C.A.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Serie95PRPanelli = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#2D4E76", minHeight: "100vh" }}>
      <Banner />
      <NewContainer />
      <Footer />
      <FloatingWhatsApp
        phoneNumber="+584244024689"
        accountName="Motobombas,c.a."
        chatMessage="Hola!, Bienvenido a Motobombas,c.a. ¿Como podemos ayudarte? "
        avatar={avatar_motobombas}
        statusMessage="Fabricantes de Bombas de agua"
      />
    </div>
  );
};

export default Serie95PRPanelli;