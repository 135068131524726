import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MotoresVerticalesEjeHuecoVHS_01 from "../images/MotoresVerticalesEjeHuecoVHS_01.png";
import MotoresVerticalesEjeHuecoVHS_02 from "../images/MotoresVerticalesEjeHuecoVHS_02.png";
import mvej_general_electric from "../images/mvej_general_electric.png";
import motorus1 from "../images/motorus1.png";
import motorus2 from "../images/motorus2.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const MotoresVerticalesEjeHuecoVHS = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Descubre la innovadora gama de Motores Verticales de Eje Hueco (VHS) de Motobombas, C.A., diseñada para superar los desafíos de aplicaciones de bombeo vertical con eficiencia y fiabilidad excepcionales. Estos motores, con su eje hueco especializado, ofrecen una solución versátil para diversas necesidades industriales y agrícolas. Con tecnología de vanguardia y un compromiso constante con la excelencia, nuestros Motores VHS aseguran un rendimiento óptimo y duradero, proporcionando la potencia esencial para impulsar tus proyectos hacia el éxito. Sumérgete en la calidad y confiabilidad que define a Motobombas, respaldada por años de experiencia e innovación en el sector de equipos de bombeo.
        </>
    );

    const textoTruncado = (
        <>
            Descubre la innovadora gama de Motores Verticales de Eje Hueco (VHS)...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresVerticalesEjeHuecoVHS_01} alt="MotoresVerticalesEjeHuecoVHS_01" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresVerticalesEjeHuecoVHS_02} alt="MotoresVerticalesEjeHuecoVHS_02" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={mvej_general_electric} alt="mvej_general_electric" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={motorus1} alt="motorus1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={motorus2} alt="motorus2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Motores Verticales de Eje Hueco (VHS)</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/MotoresVerticalesEjeHuecoVHSPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MotoresVerticalesEjeHuecoVHS;