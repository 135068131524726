import React from 'react';
import { Container, Card, Button, Row, Col, Carousel} from 'react-bootstrap';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import avatar_motobombas from "../images/avatar_motobombas.png";
import b_motores_horizontales from "../images/b_motores_horizontales.png";
import no_image_available from "../images/no_image_available.png";
import { NavLink } from "react-router-dom";

const Banner = () => {
    return (
        <Card style={{ width: 'w-100', backgroundColor: '#2D4E76' }}>
            <Card.Img variant="top" src={b_motores_horizontales} />
        </Card>
    );
};

const NewContainer = () => {
    return (
        <Container fluid style={{ backgroundColor: '#2D4E76', padding: '20px', marginTop: '20px' }}>
            <Row style={{ backgroundColor: 'white', padding: '20px' }}>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Horizontales</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={no_image_available} alt="no_image_available" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={no_image_available} alt="no_image_available" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={no_image_available} alt="no_image_available" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <NavLink href="https://wa.me/584244024689" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold' }}>Más info</NavLink>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const Contacto = () => {
    const buttonStyle = {
      backgroundColor: '#2D4E76',
      borderColor: '#2D4E76', // Color del borde
    };
  
    return (
      <Container className="d-flex justify-content-center" style={{ marginTop: "auto" }}>
        <Button variant="light" className="m-2" style={buttonStyle}>
          <a href="https://wa.me/584244024689" target="_blank" rel="noopener noreferrer">
            <WhatsAppIcon fontSize="large" style={{ color: '#FAFAFA' }} />
          </a>
        </Button>
        <Button variant="light" className="m-2" style={buttonStyle}>
          <a href="https://instagram.com/motobombasca?igshid=ZDc4ODBmNjlmNQ==" target="_blank" rel="noopener noreferrer">
            <InstagramIcon fontSize="large" style={{ color: '#FAFAFA' }} />
          </a>
        </Button>
        <Button variant="light" className="m-2" style={buttonStyle}>
          <a href="https://www.google.com/maps/place/Motobombas,+C.A.+Sede+Principal/@10.1984189,-67.9175419,17z/data=!3m1!4b1!4m6!3m5!1s0x8e80679603cd1def:0x897bd1f33654eda4!8m2!3d10.1984189!4d-67.9175419!16s%2Fg%2F11d_9hj8zl" target="_blank" rel="noopener noreferrer">
            <FmdGoodIcon fontSize="large" style={{ color: '#FAFAFA' }} />
          </a>
        </Button>
      </Container>
    );
  };
  
  const Footer = () => {
    return (
      <div
        style={{
          backgroundColor: '#2D4E76',
          color: "#FAFAFA",
          padding: "20px",
          textAlign: "left"
        }}
      >
        <Container>
          <Row>
            <Col md={3}>
              <h5>MENÚ</h5>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li><NavLink to="/bombas" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Bombas</NavLink></li>
                <li><NavLink to="/cables" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Cables</NavLink></li>
                <li><NavLink to="/componentes" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Componentes</NavLink></li>
                <li><NavLink to="/motores" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Motores</NavLink></li>
                <li><NavLink to="/tubos" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Tubos</NavLink></li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>ENLACES</h5>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                <li><NavLink to="/aboutus" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Sobre Nosotros</NavLink></li>
                <li>
                  <a href="https://wa.me/584244024689" style={{ color: "#FAFAFA", textDecoration: 'none' }}>
                    Solicitar presupuesto
                  </a>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>CONTACTÁNOS</h5>
              <p>Motobombas, C.A.<br />
              C. 93, Paraparal 2011, Carabobo,<br />
                República Bolivariana de Venezuela<br />
                Email: <a href="mailto:motoredes2021@gmail.com" style={{ color: "#FAFAFA" }}>motoredes2021@gmail.com</a><br />
                Teléfono: +58-245-4154042
              </p>
            </Col>
            <Col md={3}>
              <h5>SUCURSALES</h5>
              <p>Sucursal Oriente Teléfono: +58-283-4002563</p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Contacto />
          </Row>
          <Row className="text-center mt-3">
            <Col>
              <p>© 2023 Motobombas C.A.</p>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

const MotoresHorizontalesPag = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#2D4E76", minHeight: "100vh" }}>
            <Banner />
            <NewContainer />
            <Footer />
            <FloatingWhatsApp
                phoneNumber="+584244024689"
                accountName="Motobombas,c.a."
                chatMessage="Hola!, Bienvenido a Motobombas,c.a. ¿Como podemos ayudarte? "
                avatar={avatar_motobombas}
                statusMessage="Fabricantes de Bombas de agua"
            />
        </div>
    );
};

export default MotoresHorizontalesPag;