import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Bombas from "./pages/Bombas";
import Cables from "./pages/Cables";
import Componentes from "./pages/Componentes";
import Motores from "./pages/Motores";
import Tubos from "./pages/Tubos";
import Contacto from "./pages/Contacto";
import AboutUs from "./pages/AboutUs";
import BombasVerticalesTipoTurbinaPag from "./pages/BombasVerticalesTipoTurbinaPag";
import Serie6900Faboven from "./pages/Serie6900Faboven";
import Serie7000Faboven from "./pages/Serie7000Faboven";
import BombasHorizontalesdeSuperficiePag from "./pages/BombasHorizontalesdeSuperficiePag";
import BombassumergiblesdepozoPag from "./pages/BombasSumergiblesdePozoPag";
import Serie95PRPanelli from "./pages/Serie95PRPanelli";
import Serie95SXRECPanelli from "./pages/Serie95SXRECPanelli";
import SerieTSAFaboven4 from "./pages/SerieTSAFaboven4";
import Serie140PRPanelli from "./pages/Serie140PRPanelli";
import Serie140SXRECPanelli from "./pages/Serie140SXRECPanelli";
import SerieTSAFaboven6 from "./pages/SerieTSAFaboven6";
import Serie180SXRECPanelli from "./pages/Serie180SXRECPanelli";
import SerieTSAFaboven8 from "./pages/SerieTSAFaboven8";
import BombasSumergiblesdeAguasServidasPag from "./pages/BombasSumergiblesdeAguasServidasPag";
import CablesPlanosSumergiblesPag from "./pages/CablesPlanosSumergiblesPag";
import ComponentesGeneralPag from "./pages/ComponentesGeneralPag";
import ComponentesEléctricosPag from "./pages/ComponentesEléctricosPag";
import Contactores from "./pages/Contactores";
import MotoresVerticalesEjeHuecoVHSPag from "./pages/MotoresVerticalesEjeHuecoVHSPag";
import HT10P2BLE10HPUSMotors from "./pages/HT10P2BLE10HPUSMotors";
import HT15P2BLE15HPUSMotors from "./pages/HT15P2BLE15HPUSMotors";
import HO20P2RL20HPUSMotors from "./pages/HO20P2RL20HPUSMotors";
import HO30E2BLF30HPUSMotors from "./pages/HO30E2BLF30HPUSMotors";
import HO40P2RL40HPUSMotors from "./pages/HO40P2RL40HPUSMotors";
import HO60P2ML60HPUSMotors from "./pages/HO60P2ML60HPUSMotors";
import HO75P2RLG75HPUSMotors from "./pages/HO75P2RLG75HPUSMotors";
import GE7605GEL75HP from "./pages/GE7605GEL75HP";
import HO100P2RLG100HPUSMotors from "./pages/HO100P2RLG100HPUSMotors";
import HO100P2SLG100HPUSMotors from "./pages/HO100P2SLG100HPUSMotors";
import HT100P2CLG100HPUSMotors from "./pages/HT100P2CLG100HPUSMotors";
import HO125P2RLG125HPUSMotors from "./pages/HO125P2RLG125HPUSMotors";
import HO150P2RLG150HPUSMotors from "./pages/HO150P2RLG150HPUSMotors";
import HO200P2RLG200HPUSMotors from "./pages/HO200P2RLG200HPUSMotors";
import HO250P2RLHX250HPUSMotors from "./pages/HO250P2RLHX250HPUSMotors";
import HO300P2SLHX300HPUSMotors from "./pages/HO300P2SLHX300HPUSMotors";
import HO350P2SLHX350HPUSMotors from "./pages/HO350P2SLHX350HPUSMotors";
import HO400P2SLHX400HPUSMotors from "./pages/HO400P2SLHX400HPUSMotors";
import HO400V2SLH400HPUSMotors from "./pages/HO400V2SLH400HPUSMotors";
import HO450P2SLHX450HPUSMotors from "./pages/HO450P2SLHX450HPUSMotors";
import HO500P2SLHX500HPUSMotors from "./pages/HO500P2SLHX500HPUSMotors";
import MotoresHorizontalesPag from "./pages/MotoresHorizontalesPag";
import MotoresSumergiblesPag from "./pages/MotoresSumergiblesPag";
import TubosColumnasdeAceroalCarbonoPag from "./pages/TubosColumnasdeAceroalCarbonoPag";
import TubosColumnasdeUPVCPag from "./pages/TubosColumnasdeUPVCPag";
import Tubosupvcmotaqua from "./pages/Tubosupvcmotaqua";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="bombas" element={<Bombas />} />
          <Route path="BombasVerticalesTipoTurbinaPag" element={<BombasVerticalesTipoTurbinaPag />} />
          <Route path="Serie6900Faboven" element={<Serie6900Faboven />} />
          <Route path="Serie7000Faboven" element={<Serie7000Faboven />} />
          <Route path="BombasHorizontalesdeSuperficiePag" element={<BombasHorizontalesdeSuperficiePag />} />
          <Route path="BombassumergiblesdepozoPag" element={<BombassumergiblesdepozoPag />} />
          <Route path="Serie95PRPanelli" element={<Serie95PRPanelli />} />
          <Route path="Serie95SXRECPanelli" element={<Serie95SXRECPanelli />} />
          <Route path="SerieTSAFaboven4" element={<SerieTSAFaboven4 />} />
          <Route path="Serie140PRPanelli" element={<Serie140PRPanelli />} />
          <Route path="Serie140SXRECPanelli" element={<Serie140SXRECPanelli />} />
          <Route path="SerieTSAFaboven6" element={<SerieTSAFaboven6 />} />
          <Route path="Serie180SXRECPanelli" element={<Serie180SXRECPanelli />} />
          <Route path="SerieTSAFaboven8" element={<SerieTSAFaboven8 />} />
          <Route path="BombasSumergiblesdeAguasServidasPag" element={<BombasSumergiblesdeAguasServidasPag />} />
          <Route path="cables" element={<Cables />} />
          <Route path="CablesPlanosSumergiblesPag" element={<CablesPlanosSumergiblesPag />} />
          <Route path="componentes" element={<Componentes />} />
          <Route path="ComponentesGeneralPag" element={<ComponentesGeneralPag />} />
          <Route path="ComponentesEléctricosPag" element={<ComponentesEléctricosPag />} />
          <Route path="Contactores" element={<Contactores />} />
          <Route path="motores" element={<Motores />} />
          <Route path="MotoresVerticalesEjeHuecoVHSPag" element={<MotoresVerticalesEjeHuecoVHSPag />} />
          <Route path="HT10P2BLE10HPUSMotors" element={<HT10P2BLE10HPUSMotors />} />
          <Route path="HT15P2BLE15HPUSMotors" element={<HT15P2BLE15HPUSMotors />} />
          <Route path="HO20P2RL20HPUSMotors" element={<HO20P2RL20HPUSMotors />} />
          <Route path="HO30E2BLF30HPUSMotors" element={<HO30E2BLF30HPUSMotors />} />
          <Route path="HO40P2RL40HPUSMotors" element={<HO40P2RL40HPUSMotors />} />
          <Route path="HO60P2ML60HPUSMotors" element={<HO60P2ML60HPUSMotors />} />
          <Route path="HO75P2RLG75HPUSMotors" element={<HO75P2RLG75HPUSMotors />} />
          <Route path="GE7605GEL75HP" element={<GE7605GEL75HP />} />
          <Route path="HO100P2RLG100HPUSMotors" element={<HO100P2RLG100HPUSMotors />} />
          <Route path="HO100P2SLG100HPUSMotors" element={<HO100P2SLG100HPUSMotors />} />
          <Route path="HT100P2CLG100HPUSMotors" element={<HT100P2CLG100HPUSMotors />} />
          <Route path="HO125P2RLG125HPUSMotors" element={<HO125P2RLG125HPUSMotors />} />
          <Route path="HO150P2RLG150HPUSMotors" element={<HO150P2RLG150HPUSMotors />} />
          <Route path="HO200P2RLG200HPUSMotors" element={<HO200P2RLG200HPUSMotors />} />
          <Route path="HO250P2RLHX250HPUSMotors" element={<HO250P2RLHX250HPUSMotors />} />
          <Route path="HO300P2SLHX300HPUSMotors" element={<HO300P2SLHX300HPUSMotors />} />
          <Route path="HO350P2SLHX350HPUSMotors" element={<HO350P2SLHX350HPUSMotors />} />
          <Route path="HO400P2SLHX400HPUSMotors" element={<HO400P2SLHX400HPUSMotors />} />
          <Route path="HO400P2SLHX400HPUSMotors" element={<HO400P2SLHX400HPUSMotors />} />
          <Route path="HO400V2SLH400HPUSMotors" element={<HO400V2SLH400HPUSMotors />} />
          <Route path="HO400V2SLH400HPUSMotors" element={<HO400V2SLH400HPUSMotors />} />
          <Route path="HO450P2SLHX450HPUSMotors" element={<HO450P2SLHX450HPUSMotors />} />
          <Route path="HO500P2SLHX500HPUSMotors" element={<HO500P2SLHX500HPUSMotors />} />
          <Route path="MotoresHorizontalesPag" element={<MotoresHorizontalesPag />} />
          <Route path="MotoresSumergiblesPag" element={<MotoresSumergiblesPag />} />
          <Route path="tubos" element={<Tubos />} />
          <Route path="TubosColumnasdeAceroalCarbonoPag" element={<TubosColumnasdeAceroalCarbonoPag />} />
          <Route path="TubosColumnasdeUPVCPag" element={<TubosColumnasdeUPVCPag />} />
          <Route path="Tubosupvcmotaqua" element={<Tubosupvcmotaqua />} />
          <Route path="contacto" element={<Contacto />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
