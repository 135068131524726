import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BombasHorizontalesdeSuperficie_01 from "../images/BombasHorizontalesdeSuperficie_01.png";
import BombasHorizontalesdeSuperficie_02 from "../images/BombasHorizontalesdeSuperficie_02.png";
import BombasHorizontalesdeSuperficie_03 from "../images/BombasHorizontalesdeSuperficie_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const BombasHorizontalesdeSuperficie = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>
            Adéntrate en la excelencia de las Bombas Horizontales de Superficie de Motobombas, C.A., la solución perfecta para tus necesidades de bombeo en la superficie. Estas bombas destacan por su diseño horizontal, ofreciendo una eficiencia excepcional y facilidad de instalación. Con altos estándares de calidad, nuestras bombas aseguran un rendimiento óptimo en una variedad de aplicaciones. Desde impulsar sistemas de riego hasta abastecer instalaciones industriales, las Bombas Horizontales de Superficie de Motobombas, C.A. se destacan por su confiabilidad y durabilidad. Descubre la potencia que tu proyecto merece y confía en nosotros para superar tus expectativas con cada flujo de agua. Eleva tu eficiencia con nuestras Bombas Horizontales de Superficie y experimenta el rendimiento excepcional que solo Motobombas, C.A. puede ofrecer.
        </>
    );

    const textoTruncado = (
        <>
            Adéntrate en la excelencia de las Bombas Horizontales de Superfic...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasHorizontalesdeSuperficie_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasHorizontalesdeSuperficie_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasHorizontalesdeSuperficie_03} alt="Imagen 3" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Horizontales de Superficie</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/BombasHorizontalesdeSuperficiePag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BombasHorizontalesdeSuperficie;
