import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CablesPlanosSumergibles_01 from "../images/CablesPlanosSumergibles_01.png";
import CablesPlanosSumergibles_02 from "../images/CablesPlanosSumergibles_02.png";
import CablesPlanosSumergibles_03 from "../images/CablesPlanosSumergibles_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const CablesPlanosSumergibles = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Sumérgete en la excelencia con nuestros Cables Planos Sumergibles de Motobombas, C.A., diseñados para superar los desafíos más exigentes en entornos acuáticos profundos. Estos cables representan la solución perfecta para aplicaciones sumergibles, destacándose por su calidad y resistencia a la corrosión. Fabricados con materiales de alta calidad, ofrecen un rendimiento confiable y duradero, garantizando una conexión eléctrica segura y eficiente para tus sistemas de bombeo. Descubre la tranquilidad de contar con cables diseñados para resistir las condiciones más adversas, asegurando un suministro eléctrico robusto y sin complicaciones en entornos subacuáticos. Con Motobombas, elevamos la eficiencia y la durabilidad a nuevas profundidades.
        </>
    );

    const textoTruncado = (
        <>
            Sumérgete en la excelencia con nuestros Cables Planos Sumergible...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={CablesPlanosSumergibles_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={CablesPlanosSumergibles_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={CablesPlanosSumergibles_03} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Sumergibles de Aguas Servidas</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/CablesPlanosSumergibles"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default CablesPlanosSumergibles;
