import React from 'react';
import { useEffect, useState } from 'react';
import { Card, Carousel, Container, Row, Col, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import bombas_01 from "../images/bombas_01.png";
import baner_marcas_01 from "../images/baner_marcas_01.png";
import baner_marcas_02 from "../images/baner_marcas_02.png";
import baner_marcas_03 from "../images/baner_marcas_03.png";
import baner_marcas_04 from "../images/baner_marcas_04.png";
import cables_01 from "../images/cables_01.png";
import cables_02 from "../images/cables_02.png";
import cables_03 from "../images/cables_03.png";
import componentes_01 from "../images/componentes_01.png";
import componentes_02 from "../images/componentes_02.png";
import componentes_03 from "../images/componentes_03.png";
import motores_01 from "../images/motores_01.png";
import motores_02 from "../images/motores_02.png";
import motores_03 from "../images/motores_03.png";
import tubos_01 from "../images/tubos_01.png";
import tubos_02 from "../images/tubos_02.png";
import tubos_03 from "../images/tubos_03.png";
import b_subtitulo from "../images/b_subtitulo.png";
import portada from "../images/portada.png";
import avatar_motobombas from "../images/avatar_motobombas.png";
import { NavLink } from "react-router-dom";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { InstagramEmbed } from 'react-social-media-embed';
import ScrollToTopButton from './ScrollToTopButton';

const About = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 2560 && window.innerHeight >= 1600) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Comprueba el tamaño de la pantalla al cargar

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const titleStyle = {
    fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
    color: "#FAFAFA",
    fontSize: isLargeScreen ? "100px" : "36px",
    marginTop: isLargeScreen ? "-80px" : "0px",
    textAlign: "center",
  };

  const paragraphStyle = {
    fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
    color: "#FAFAFA",
    fontSize: isLargeScreen ? "40px" : "16px",
    textAlign: "justify",
    marginTop: "10px",
  };

  return (
    <section id="about-us" style={{ marginTop: '20px', backgroundColor: '#2D4E76' }}>
      <Container fluid>
        <Row className="align-items-center justify-content-center">
          <Col lg={6} className="d-flex justify-content-center mb-4 mb-lg-0">
            <img
              src={portada}
              alt="Imagen de la empresa"
              className="img-fluid"
              style={{ maxWidth: '80%', height: 'auto' }}
            />
          </Col>
          <Col lg={6} className="text-center d-flex flex-column align-items-center justify-content-center">
            <h2 style={titleStyle} className={isLargeScreen ? 'large-font' : ''}>Sobre Nosotros</h2>
            <p style={paragraphStyle} className={isLargeScreen ? 'large-font' : ''}>
              Motobombas C.A. líder en fabricación y distribución de motobombas de alta calidad. Nos destacamos por la excelencia e innovación, ofreciendo soluciones eficientes respaldadas por un servicio postventa excepcional. Priorizamos la sostenibilidad ambiental. Elija Motobombas C.A. para equipos confiables. ¡Contáctenos y descubra cómo podemos ayudarlo!
            </p>
            <div className="embed-responsive embed-responsive-16by9" style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/jNIXngzc33c"
                title="Video sobre nosotros"
                frameBorder="0"
                allowFullScreen
                style={{ width: '100%', height: '315px' }}
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const Subtitulo = () => {
  return (
    <div style={{ backgroundColor: "#2D4E76" }}>
      <img className="d-block w-100" src={b_subtitulo} alt="Subtitulo" />
    </div>
  );
};

const Carruselmarcas = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={baner_marcas_01}
          alt="First slide"
        />
        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={baner_marcas_02}
          alt="Second slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={baner_marcas_03}
          alt="Third slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={baner_marcas_04}
          alt="Fourth slide"
        />

        <Carousel.Caption></Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

const MarcasCard = ({ title, links, images }) => {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 2560 && window.innerHeight >= 1600) {
        setIsLargeScreen(true);
      } else {
        setIsLargeScreen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Comprueba el tamaño de la pantalla al cargar

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const cardStyle = {
    width: '100%',
    maxWidth: isLargeScreen ? '150%' : '100%', // Cambia el valor del maxWidth para pantallas grandes
    marginBottom: '15px',
    height: isLargeScreen ? '700px' : '420px',
    margin: isLargeScreen ? '0px' : '10px', // Aumenta el margen en pantallas grandes
  };

  const titleStyle = {
    textAlign: "center",
    color: "#2D4E76",
    fontSize: isLargeScreen ? "40px" : "20px",
  };

  const textStyle = {
    textAlign: "center",
    color: "#2D4E76",
    fontSize: isLargeScreen ? "24px" : "12px",
  };

  return (
    <Card style={cardStyle}>
      <Card.Body>
        <Card.Title style={titleStyle}>
          {title}
        </Card.Title>
        <Card.Text style={textStyle}>
          {images && (
            <div>
              <Carousel>
                {images.map((image, index) => (
                  <Carousel.Item key={index}>
                    <img className="d-block w-100" src={image} alt={`Slide ${index}`} />
                    <Carousel.Caption></Carousel.Caption>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          )}
          {links && (
            <div>
              {links.map((link, index) => (
                <Link key={index} to={link.url} style={{ display: 'block', color: 'blue', cursor: 'pointer', marginBottom: '5px', fontFamily: 'Glober, sans-serif', textDecoration: 'none', fontWeight: 'bold' }}>
                  {link.text}
                </Link>
              ))}
            </div>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

const Marcas = () => {
  const marcasData = [
    {
      title: "Bombas",
      links: [
        { text: "Verticales Tipo Turbina", url: "/BombasVerticalesTipoTurbinaPag" },
        { text: "Horizontales de Superficie", url: "/BombasHorizontalesdeSuperficiePag" },
        { text: "Sumergibles de Pozo", url: "/BombassumergiblesdepozoPag" },
        { text: "Bombas Sumergibles de Aguas Servidas", url: "/BombasSumergiblesdeAguasServidasPag" },
      ],
      images: [bombas_01],
    },
    {
      title: "Cables",
      links: [
        { text: "Planos Sumergibles", url: "CablesPlanosSumergiblesPag" },
      ],
      images: [cables_01, cables_02, cables_03],
    },
    {
      title: "Componentes",
      links: [
        { text: "Componentes", url: "ComponentesGeneralPag" },
        { text: "Componentes Eléctricos", url: "ComponentesEléctricosPag" },
      ],
      images: [componentes_01, componentes_02, componentes_03],
    },
    {
      title: "Motores",
      links: [
        { text: "Motores Verticales de Eje Hueco (VHS)", url: "MotoresVerticalesEjeHuecoVHSPag" },
        { text: "Motores Horizontales", url: "MotoresHorizontalesPag" },
        { text: "Motores Sumergibles", url: "MotoresSumergiblesPag" },
      ],
      images: [motores_01, motores_02, motores_03],
    },
    {
      title: "Tubos",
      links: [
        { text: "Tubos Columnas de Acero al Carbono", url: "TubosColumnasdeAceroalCarbonoPag" },
        { text: "Tubos Columnas de UPVC", url: "TubosColumnasdeUPVCPag" },
      ],
      images: [tubos_01, tubos_02, tubos_03],
    },
  ];

  return (
    <div style={{ margin: "40px 40px", backgroundColor: "#2D4E76" }}>
      <Container>
        <Row style={{ justifyContent: "center" }}>
          {marcasData.map((marca, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} xl={2} style={{ marginBottom: "40px" }}>
              <MarcasCard {...marca} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

const InstagramPost = () => {
  return (
    <div style={{ overflow: 'hidden', maxWidth: '100%', margin: '0 auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginBottom: '40px' }}>
        <InstagramEmbed url="https://instagram.com/motobombasca?igshid=ZDc4ODBmNjlmNQ==" width={656} />
      </div>
    </div>
  );
};

const Contacto = () => {
  const buttonStyle = {
    backgroundColor: '#2D4E76',
    borderColor: '#2D4E76', // Color del borde
  };

  return (
    <Container className="d-flex justify-content-center" style={{ marginTop: "auto" }}>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://wa.me/584244024689" target="_blank" rel="noopener noreferrer">
          <WhatsAppIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://instagram.com/motobombasca?igshid=ZDc4ODBmNjlmNQ==" target="_blank" rel="noopener noreferrer">
          <InstagramIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
      <Button variant="light" className="m-2" style={buttonStyle}>
        <a href="https://www.google.com/maps/place/Motobombas,+C.A.+Sede+Principal/@10.1984189,-67.9175419,17z/data=!3m1!4b1!4m6!3m5!1s0x8e80679603cd1def:0x897bd1f33654eda4!8m2!3d10.1984189!4d-67.9175419!16s%2Fg%2F11d_9hj8zl" target="_blank" rel="noopener noreferrer">
          <FmdGoodIcon fontSize="large" style={{ color: '#FAFAFA' }} />
        </a>
      </Button>
    </Container>
  );
};

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: '#2D4E76',
        color: "#FAFAFA",
        padding: "20px",
        textAlign: "left"
      }}
    >
      <Container>
        <Row>
          <Col md={3}>
            <h5>MENÚ</h5>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li><NavLink to="/bombas" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Bombas</NavLink></li>
              <li><NavLink to="/cables" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Cables</NavLink></li>
              <li><NavLink to="/componentes" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Componentes</NavLink></li>
              <li><NavLink to="/motores" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Motores</NavLink></li>
              <li><NavLink to="/tubos" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Tubos</NavLink></li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>ENLACES</h5>
            <ul style={{ listStyleType: "none", padding: 0 }}>
              <li><NavLink to="/aboutus" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Sobre Nosotros</NavLink></li>
              <li>
                <a href="https://wa.me/584244024689" style={{ color: "#FAFAFA", textDecoration: 'none' }}>
                  Solicitar presupuesto
                </a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h5>CONTACTÁNOS</h5>
            <p>Motobombas, C.A.<br />
            C. 93, Paraparal 2011, Carabobo,<br />
              República Bolivariana de Venezuela<br />
              Email: <a href="mailto:motoredes2021@gmail.com" style={{ color: "#FAFAFA" }}>motoredes2021@gmail.com</a><br />
              Teléfono: +58-245-4154042
            </p>
          </Col>
          <Col md={3}>
            <h5>SUCURSALES</h5>
            <p>Sucursal Oriente Teléfono: +58-283-4002563</p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Contacto />
        </Row>
        <Row className="text-center mt-3">
          <Col>
            <p>© 2023 Motobombas C.A.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Home = () => {
  return (
    <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#2D4E76", minHeight: "100vh" }}>
      <About style={{ flex: "1" }} />
      <Subtitulo />
      <Marcas />
      <InstagramPost />
      <Carruselmarcas />
      <Footer />
      <ScrollToTopButton />
      <FloatingWhatsApp
        phoneNumber="+584244024689"
        accountName="Motobombas,c.a."
        chatMessage="Hola!, Bienvenido a Motobombas,c.a. ¿Como podemos ayudarte? "
        avatar={avatar_motobombas}
        statusMessage="Fabricantes de Bombas de agua"
      />
    </div>
  );
};

export default Home;
