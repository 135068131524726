import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BombasSumergiblesdePozo_01 from "../images/BombasSumergiblesdePozo_01.png";
import BombasSumergiblesdePozo_02 from "../images/BombasSumergiblesdePozo_02.png";
import BombasSumergiblesdePozo_03 from "../images/BombasSumergiblesdePozo_03.png";
import BombasSumergiblesdePozo_04 from "../images/BombasSumergiblesdePozo_04.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const BombasSumergiblesdePozo = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>
            Sumérgete en la innovación con las Bombas Sumergibles de Pozo de Motobombas, C.A., la opción confiable y eficiente para extraer agua de pozos profundos. Diseñadas para garantizar un rendimiento excepcional, estas bombas son la elección perfecta para abastecer sistemas de riego, suministrar agua potable o cualquier aplicación que requiera un flujo constante desde las profundidades del subsuelo. Con materiales de alta calidad y tecnología avanzada, nuestras Bombas Sumergibles de Pozo destacan por su resistencia a la corrosión y durabilidad a largo plazo. Confía en Motobombas, C.A. para proporcionar soluciones de bombeo sumergible que superen tus expectativas y te permitan aprovechar al máximo los recursos hídricos subterráneos. Sumérgete en el futuro del bombeo eficiente con nuestras Bombas Sumergibles de Pozo y experimenta un flujo constante de confianza.
        </>
    );

    const textoTruncado = (
        <>
            Sumérgete en la innovación con las Bombas Sumergibles de Pozo de...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdePozo_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdePozo_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdePozo_03} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdePozo_04} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Sumergibles de Pozo</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/BombassumergiblesdepozoPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BombasSumergiblesdePozo;
