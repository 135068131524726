import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import bombas_verticales_tipo_turbina_01 from "../images/bombas_verticales_tipo_turbina_01.png";
import bombas_verticales_tipo_turbina_02 from "../images/bombas_verticales_tipo_turbina_02.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const BombasVerticalesTipoTurbina = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>
            Descubre la excelencia en bombeo con las Bombas Verticales Tipo Turbina de Motobombas, C.A. Nuestra línea representa la combinación perfecta de potencia y versatilidad, con capacidades que van desde 0,5 HP hasta 10 HP, alturas de bombeo impresionantes de hasta 470 metros y un caudal eficiente que varía entre 0,2 y 8 litros por segundo. Estas bombas destacan por su construcción robusta, utilizando materiales de alta calidad como el bronce, acero inoxidable y policarbonato, lo que las hace resistentes y duraderas. Además, enfrentan desafíos como la presencia de arena en el agua, manejando hasta 300 gramos por metro cúbico. En Motobombas, C.A., nos comprometemos a ofrecer innovación y calidad respaldadas por décadas de experiencia. Eleva tu proyecto al próximo nivel con nuestras Bombas Verticales Tipo Turbina, diseñadas para superar tus expectativas y brindar un rendimiento óptimo y duradero. Confía en nosotros para impulsar tus proyectos con la excelencia en ingeniería de Motobombas, C.A.
        </>
    );

    const textoTruncado = (
        <>
           Descubre la excelencia en bombeo con las Bombas Verticales Tipo T...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={bombas_verticales_tipo_turbina_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={bombas_verticales_tipo_turbina_02} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Verticales Tipo Turbina</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                                <Button variant="light" className="m-2"><Link to="/BombasverticalesTipoTurbinaPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BombasVerticalesTipoTurbina;
