import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TubosColumnasdeUPVC_01 from "../images/TubosColumnasdeUPVC_01.png";
import TubosColumnasdeUPVC_02 from "../images/TubosColumnasdeUPVC_02.png";
import TubosColumnasdeUPVC_03 from "../images/TubosColumnasdeUPVC_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const TubosColumnasdeUPVC = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Descubre la innovación y versatilidad en nuestra sección de Columnas de UPVC en Motobombas, C.A. Estas columnas, fabricadas con UPVC de alta calidad, ofrecen soluciones eficientes y resistentes para la conducción de fluidos en sistemas de bombeo. Diseñadas para enfrentar diversos entornos y aplicaciones, nuestras columnas de UPVC destacan por su durabilidad, resistencia a la corrosión y bajo mantenimiento. Explora la confianza en cada instalación, respaldada por la calidad distintiva de Motobombas en el ámbito de equipos hidráulicos.
        </>
    );

    const textoTruncado = (
        <>
            Descubre la innovación y versatilidad en nuestra sección de Colum...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={TubosColumnasdeUPVC_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={TubosColumnasdeUPVC_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={TubosColumnasdeUPVC_03} alt="Imagen 3" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Columnas de UPVC</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/TubosColumnasdeUPVCPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TubosColumnasdeUPVC;