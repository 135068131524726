import React from 'react';
import { Container, Card, Button, Row, Col, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import avatar_motobombas from "../images/avatar_motobombas.png";
import b_motores_verticales_eje_hueco_vhs from "../images/b_motores_verticales_eje_hueco_vhs.png";
import mvej_general_electric from "../images/mvej_general_electric.png";
import motorus1 from "../images/motorus1.png";
import motorus2 from "../images/motorus2.png";
import { NavLink } from "react-router-dom";
import ScrollToTopButton from './ScrollToTopButton';

const Banner = () => {
    return (
        <Card style={{ width: 'w-100', backgroundColor: '#2D4E76' }}>
            <Card.Img variant="top" src={b_motores_verticales_eje_hueco_vhs} />
        </Card>
    );
};

const NewContainer = () => {
    return (
        <Container fluid style={{ backgroundColor: '#2D4E76', padding: '20px', marginTop: '20px' }}>
            <Row style={{ backgroundColor: 'white', padding: '20px' }}>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 10 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus1} alt="motorus1" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HT10P2BLE10HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HT10P2BLE 10 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 15 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus1} alt="motorus1" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HT15P2BLE15HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HT15P1BLE 15 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 20 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO20P2RL20HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO20P2RL  20 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 30 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO30E2BLF30HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO30E2BLF 30 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 40 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO40P2RL40HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO40P2RL 40 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 60 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO60P2ML60HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO60P2ML 60 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 75 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={mvej_general_electric} alt="mvej_general_electric" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO75P2RLG75HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO75P2RLG 75 HP US Motors</Link>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/GE7605GEL75HP" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>7605GEL-75 75 HP General Electric</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 100 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2 " />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus1} alt="motorus1 " />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO100P2RLG100HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO100P2RLG 100 HP US Motors</Link>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO100P2SLG100HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO100P2SLG 100 HP US Motors</Link>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HT100P2CLG100HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HT100P2CLG 100 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 125 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO125P2RLG125HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO125P2RLG 125 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 150 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO150P2RLG150HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO150P2RLG 150 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 200 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO200P2RLG200HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO200P2RLG 200 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 250 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO250P2RLHX250HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO250P2RLHX 250 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 300 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO300P2SLHX300HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO300P2SLHX 300 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 350 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO350P2SLHX350HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO350P2SLHX 350 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 400 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO400P2SLHX400HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO400P2SLHX 400 HP US Motors</Link>
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO400V2SLH400HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO400V2SLH 400 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 450 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO450P2SLHX450HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO450P2SLHX 450 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className="mb-4 text-center">
                    <Card border="light">
                        <Card.Title style={{ fontSize: '24px', fontWeight: 'bold' }}>Motores (VHS) 500 HP</Card.Title>
                        <Card.Body>
                            <Carousel>
                                <Carousel.Item>
                                    <img className="d-block w-100" src={motorus2} alt="motorus2" />
                                </Carousel.Item>
                            </Carousel>
                            <Card.Text>
                                <table style={{ margin: 'auto', textAlign: 'center', border: '1px solid black', borderCollapse: 'collapse' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: 'left', border: '1px solid black', padding: '8px' }}>Más info</td>
                                            <td style={{ textAlign: 'right', border: '1px solid black', padding: '8px' }}>Click Link </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{ marginTop: '10px' }}>
                                    <Link to="/HO500P2SLHX500HPUSMotors" style={{ display: 'block', color: 'blue', cursor: 'pointer', fontFamily: 'Glober, sans-serif', fontWeight: 'bold', textDecoration: 'none' }}>HO500P2SLHX 500 HP US Motors</Link>
                                </div>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const Contacto = () => {
    const buttonStyle = {
        backgroundColor: '#2D4E76',
        borderColor: '#2D4E76', // Color del borde
    };

    return (
        <Container className="d-flex justify-content-center" style={{ marginTop: "auto" }}>
            <Button variant="light" className="m-2" style={buttonStyle}>
                <a href="https://wa.me/584244024689" target="_blank" rel="noopener noreferrer">
                    <WhatsAppIcon fontSize="large" style={{ color: '#FAFAFA' }} />
                </a>
            </Button>
            <Button variant="light" className="m-2" style={buttonStyle}>
                <a href="https://instagram.com/motobombasca?igshid=ZDc4ODBmNjlmNQ==" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon fontSize="large" style={{ color: '#FAFAFA' }} />
                </a>
            </Button>
            <Button variant="light" className="m-2" style={buttonStyle}>
                <a href="https://www.google.com/maps/place/Motobombas,+C.A.+Sede+Principal/@10.1984189,-67.9175419,17z/data=!3m1!4b1!4m6!3m5!1s0x8e80679603cd1def:0x897bd1f33654eda4!8m2!3d10.1984189!4d-67.9175419!16s%2Fg%2F11d_9hj8zl" target="_blank" rel="noopener noreferrer">
                    <FmdGoodIcon fontSize="large" style={{ color: '#FAFAFA' }} />
                </a>
            </Button>
        </Container>
    );
};

const Footer = () => {
    return (
        <div
            style={{
                backgroundColor: '#2D4E76',
                color: "#FAFAFA",
                padding: "20px",
                textAlign: "left"
            }}
        >
            <Container>
                <Row>
                    <Col md={3}>
                        <h5>MENÚ</h5>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            <li><NavLink to="/bombas" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Bombas</NavLink></li>
                            <li><NavLink to="/cables" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Cables</NavLink></li>
                            <li><NavLink to="/componentes" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Componentes</NavLink></li>
                            <li><NavLink to="/motores" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Motores</NavLink></li>
                            <li><NavLink to="/tubos" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Tubos</NavLink></li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <h5>ENLACES</h5>
                        <ul style={{ listStyleType: "none", padding: 0 }}>
                            <li><NavLink to="/aboutus" style={{ color: "#FAFAFA", textDecoration: 'none' }}>Sobre Nosotros</NavLink></li>
                            <li>
                                <a href="https://wa.me/584244024689" style={{ color: "#FAFAFA", textDecoration: 'none' }}>
                                    Solicitar presupuesto
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3}>
                        <h5>CONTACTÁNOS</h5>
                        <p>Motobombas, C.A.<br />
                            C. 93, Paraparal 2011, Carabobo,<br />
                            República Bolivariana de Venezuela<br />
                            Email: <a href="mailto:motoredes2021@gmail.com" style={{ color: "#FAFAFA" }}>motoredes2021@gmail.com</a><br />
                            Teléfono: +58-245-4154042
                        </p>
                    </Col>
                    <Col md={3}>
                        <h5>SUCURSALES</h5>
                        <p>Sucursal Oriente Teléfono: +58-283-4002563</p>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Contacto />
                </Row>
                <Row className="text-center mt-3">
                    <Col>
                        <p>© 2023 Motobombas C.A.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

const MotoresVerticalesEjeHuecoVHSPag = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", backgroundColor: "#2D4E76", minHeight: "100vh" }}>
            <Banner />
            <NewContainer />
            <Footer />
            <ScrollToTopButton />
            <FloatingWhatsApp
                phoneNumber="+584244024689"
                accountName="Motobombas,c.a."
                chatMessage="Hola!, Bienvenido a Motobombas,c.a. ¿Como podemos ayudarte? "
                avatar={avatar_motobombas}
                statusMessage="Fabricantes de Bombas de agua"
            />
        </div>
    );
};

export default MotoresVerticalesEjeHuecoVHSPag;