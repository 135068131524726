import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BombasSumergiblesdeAguasServidas_01 from "../images/BombasSumergiblesdeAguasServidas_01.png";
import BombasSumergiblesdeAguasServidas_02 from "../images/BombasSumergiblesdeAguasServidas_02.png";
import BombasSumergiblesdeAguasServidas_03 from "../images/BombasSumergiblesdeAguasServidas_03.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const BombasSumergiblesdeAguasServidas = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>
            Desafía las aguas servidas con la potencia incomparable de las Bombas Sumergibles de Aguas Servidas de Motobombas, C.A. Diseñadas para enfrentar entornos exigentes, estas bombas son la solución eficaz para el manejo seguro y eficiente de aguas residuales. Con capacidades de bombeo robustas, nuestras Bombas Sumergibles de Aguas Servidas garantizan un rendimiento fiable incluso en condiciones adversas. Equipadas con tecnología de vanguardia y construidas con materiales resistentes, estas bombas ofrecen una solución duradera para la evacuación eficiente de aguas residuales, contribuyendo a la preservación del medio ambiente y la salud pública. Confía en Motobombas, C.A. para superar los desafíos de las aguas servidas con soluciones de bombeo avanzadas y eficientes, asegurando un flujo constante hacia un entorno más limpio y saludable. Sumérgete en la confiabilidad y el rendimiento superior con nuestras Bombas Sumergibles de Aguas Servidas.
        </>
    );

    const textoTruncado = (
        <>
            Desafía las aguas servidas con la potencia incomparable de las Bo...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdeAguasServidas_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdeAguasServidas_02} alt="Imagen 2" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={BombasSumergiblesdeAguasServidas_03} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Sumergibles de Aguas Servidas</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/BombasSumergiblesdeAguasServidasPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BombasSumergiblesdeAguasServidas;
