import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TubosColumnasdeAceroalCarbono_01 from "../images/TubosColumnasdeAceroalCarbono_01.png";
import TubosColumnasdeAceroalCarbono_02 from "../images/TubosColumnasdeAceroalCarbono_02.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const TubosColumnasdeAceroalCarbono = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Explora la durabilidad y resistencia de nuestras Columnas de Acero al Carbono en Motobombas, C.A., una sección dedicada a brindar soluciones robustas para la conducción eficiente de fluidos en sistemas de bombeo. Diseñadas con precisión y fabricadas con materiales de alta calidad, nuestras columnas de acero al carbono ofrecen una estructura sólida y confiable para aplicaciones en pozos profundos. Estas columnas son la elección ideal para garantizar un flujo constante y seguro en entornos desafiantes. Descubre la calidad que define a Motobombas en cada detalle de nuestras Columnas de Acero al Carbono, respaldada por un compromiso constante con la excelencia en ingeniería hidráulica.
        </>
    );

    const textoTruncado = (
        <>
            Explora la durabilidad y resistencia de nuestras Columnas de Acer...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={TubosColumnasdeAceroalCarbono_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={TubosColumnasdeAceroalCarbono_02} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Columnas de Acero al Carbono</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/TubosColumnasdeAceroalCarbonoPag"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default TubosColumnasdeAceroalCarbono;