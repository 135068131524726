import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MotoresHorizontales_01 from "../images/MotoresHorizontales_01.png";
import MotoresHorizontales_02 from "../images/MotoresHorizontales_02.png";
import FindInPageIcon from '@mui/icons-material/FindInPage';

const MotoresHorizontales = () => {
    const [mostrarCompleto, setMostrarCompleto] = useState(false);

    const handleClick = () => {
        setMostrarCompleto(!mostrarCompleto);
    };

    const textoCompleto = (
        <>

            Explora la excepcional línea de Motores Horizontales de Motobombas, C.A., diseñada para ofrecer un rendimiento óptimo en una amplia variedad de aplicaciones de bombeo. Nuestros motores horizontales destacan por su eficiencia, durabilidad y versatilidad, brindando la potencia necesaria para afrontar desafíos industriales y agrícolas con éxito. Con tecnología de vanguardia y un compromiso inquebrantable con la calidad, Motobombas garantiza soluciones confiables que impulsan tus proyectos hacia nuevos niveles de eficacia. Sumérgete en la excelencia de los Motores Horizontales de Motobombas, respaldados por años de experiencia y liderazgo en el sector de equipos de bombeo.
        </>
    );

    const textoTruncado = (
        <>
            Explora la excepcional línea de Motores Horizontales de Motobombas...
        </>
    );

    return (
        <Container fluid>
            <Row className="justify-content-center mt-4">
                <Col xs={12} md={2} className="mb-4">
                    <Card border="light">
                        <Carousel>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresHorizontales_01} alt="Imagen 1" />
                            </Carousel.Item>
                            <Carousel.Item>
                                <img className="d-block w-100" src={MotoresHorizontales_02} alt="Imagen 2" />
                            </Carousel.Item>
                        </Carousel>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Motores Horizontales</Card.Title>
                            <Card.Text>
                                {mostrarCompleto ? textoCompleto : textoTruncado}
                                <span style={{ color: 'blue', cursor: 'pointer' }} onClick={handleClick}>
                                    {mostrarCompleto ? ' Mostrar menos' : ' Mostrar más'}
                                </span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className="mb-4">
                    <Card border="light">
                        <Card.Body>
                            <Card.Title>Mas información</Card.Title>
                            <Card.Text>
                            <Button variant="light" className="m-2"><Link to="/MotoresHorizontales"><FindInPageIcon fontSize='large' color='primary' /></Link></Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default MotoresHorizontales;