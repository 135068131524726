import React, { useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom"; // Importa useNavigate desde react-router-dom
import { Navbar, Nav, NavDropdown, Form, Button, Dropdown } from "react-bootstrap";
import { FaBars, FaTimes } from 'react-icons/fa'; // Importa los íconos de flecha
import "bootstrap/dist/css/bootstrap.min.css";
import logo_motobombas_white from "../images/logo_motobombas_white.png";

const Layout = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [bombasExpanded, setBombasExpanded] = useState(false);
  const [cablesExpanded, setCablesExpanded] = useState(false);
  const [componentesExpanded, setComponentesExpanded] = useState(false);
  const [motoresExpanded, setMotoresExpanded] = useState(false);
  const [tubosExpanded, setTubosExpanded] = useState(false);

  const navigate = useNavigate();

  const closeAllPanelsExcept = (panelSetter) => {
    const panelSetters = [setBombasExpanded, setCablesExpanded, setComponentesExpanded, setMotoresExpanded, setTubosExpanded];
    panelSetters.forEach((setExpanded) => {
      if (setExpanded !== panelSetter) {
        // Cierra todos los paneles excepto el especificado
        setExpanded(false);
      }
    });
  };

  const handleNavToggle = () => {
    setIsNavExpanded(!isNavExpanded);
    // Cierra todos los paneles cuando se expande/despliega la navegación principal
    closeAllPanelsExcept(null);
  };

  const handleNavLinkClick = () => {
    // Close the mobile menu when a navigation link is clicked
    setIsNavExpanded(false);
  };

  const handleBombasToggle = () => {
    setBombasExpanded((prev) => !prev);
    closeAllPanelsExcept(setBombasExpanded);
  };

  const handleCablesToggle = () => {
    setCablesExpanded((prev) => !prev);
    closeAllPanelsExcept(setCablesExpanded);
  };

  const handleComponentesToggle = () => {
    setComponentesExpanded((prev) => !prev);
    closeAllPanelsExcept(setComponentesExpanded);
  };

  const handleMotoresToggle = () => {
    setMotoresExpanded((prev) => !prev);
    closeAllPanelsExcept(setMotoresExpanded);
  };

  const handleTubosToggle = () => {
    setTubosExpanded((prev) => !prev);
    closeAllPanelsExcept(setTubosExpanded);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const searchTerm = e.target.search.value.trim(); // Obtiene el valor del cuadro de búsqueda y elimina espacios en blanco

    // Realiza la lógica de búsqueda aquí para determinar la ruta a la que quieres redirigir
    // Por ejemplo, podrías definir un objeto de búsqueda que mapee términos a rutas
    const searchRoutes = {
      "bombas": "/bombas",
      "Bombas": "/bombas",
      "Bombas Verticales Tipo Turbina": "/BombasVerticalesTipoTurbina",
      "Bombas Horizontales de Superficie": "/BombasHorizontalesdeSuperficie",
      "Bombas Sumergibles de Pozo": "/Bombassumergiblesdepozo",
      "Bombas Sumergibles de Aguas Servidas": "BombasSumergiblesdeAguasServidas",
      "cables": "/cables",
      "Cables": "/cables",
      "Cables Planos Sumergibles": "/CablesPlanosSumergibles",
      "componentes": "/componentes",
      "Componentes": "/componentes",
      "Componentes General": "/ComponentesGeneral",
      "Componentes Eléctricos": "/ComponentesEléctricos",
      "motores": "/motores",
      "Motores": "/motores",
      "Motores Verticales Eje Hueco VHS": "/MotoresVerticalesEjeHuecoVHS",
      "Motores Horizontales": "/MotoresHorizontales",
      "Motores Sumergibles": "/MotoresSumergibles",
      "tubos": "/tubos",
      "Tubos": "/tubos",
      "Tubos Columnas de Acero al Carbono": "/TubosColumnasdeAceroalCarbono",
      "Tubos Columnas de UPVC": "/TubosColumnasdeUPVC",
      // Agrega más términos y rutas según sea necesario
    };

    if (searchRoutes[searchTerm]) {
      // Si se encuentra una ruta para el término de búsqueda, redirige a esa ruta
      navigate(searchRoutes[searchTerm]);
    } else {
      // Si no se encuentra una ruta para el término de búsqueda, puedes realizar acciones adicionales, como mostrar un mensaje de error
      console.log("No se encontró una ruta para el término de búsqueda:", searchTerm);
    }

    // Restablece el cuadro de búsqueda después de la búsqueda
    e.target.search.value = "";
  };

  return (
    <>
      <Navbar
        expand="md"
        style={{ backgroundColor: "#2D4E76", justifyContent: "center" }}
        variant="dark"
      >
        <Navbar.Toggle
          // ... (other props)
          onClick={handleNavToggle} // Use handleNavToggle here
        >
          {isNavExpanded ? <FaTimes /> : <FaBars />}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Navbar.Brand as={Link} to="/">
              <img
                src={logo_motobombas_white}
                alt="Logo"
                height="30"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
            <Nav.Link
              as={Link}
              to="/"
              style={{
                color: "#fff",
                fontFamily:
                  "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
              }}
              onClick={handleNavLinkClick}
            >
              Home
            </Nav.Link>
            <NavDropdown
              title="Productos"
              id="productos-dropdown"
              style={{
                fontFamily:
                  "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
              }}
            >
              <Dropdown.Toggle
                style={{
                  color: "#fff",
                  fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "#fafafa",
                }}
                onClick={handleBombasToggle}
              >
                Bombas
              </Dropdown.Toggle>
              <NavDropdown.Item
                as={Link}
                to="/BombasverticalesTipoTurbinaPag"
                style={{
                  display: bombasExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Verticales Tipo Turbina
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/BombasHorizontalesdeSuperficiePag"
                style={{
                  display: bombasExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Horizontales de Superficie
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/BombassumergiblesdepozoPag"
                style={{
                  display: bombasExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Sumergibles de Pozo
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/BombasSumergiblesdeAguasServidasPag"
                style={{
                  display: bombasExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Sumergibles de Aguas Servidas
              </NavDropdown.Item>
              <Dropdown.Toggle
                style={{
                  color: "#fff",
                  fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "#fafafa",
                }}
                onClick={handleCablesToggle}
              >
                Cables
              </Dropdown.Toggle>
              <NavDropdown.Item
                as={Link}
                to="/CablesPlanosSumergiblesPag"
                style={{
                  display: cablesExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Cables Planos Sumergibles
              </NavDropdown.Item>
              <Dropdown.Toggle
                style={{
                  color: "#fff",
                  fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "#fafafa",
                }}
                onClick={handleComponentesToggle}
              >
                Componentes
              </Dropdown.Toggle>
              <NavDropdown.Item
                as={Link}
                to="/ComponentesGeneralPag"
                style={{
                  display: componentesExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Componentes
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/ComponentesEléctricosPag"
                style={{
                  display: componentesExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Componentes Eléctricos
              </NavDropdown.Item>
              <Dropdown.Toggle
                style={{
                  color: "#fff",
                  fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "#fafafa",
                }}
                onClick={handleMotoresToggle}
              >
                Motores
              </Dropdown.Toggle>
              <NavDropdown.Item
                as={Link}
                to="/MotoresVerticalesEjeHuecoVHSPag"
                style={{
                  display: motoresExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Motores Verticales de Eje Hueco (VHS)
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/MotoresHorizontalesPag"
                style={{
                  display: motoresExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Motores Horizontales
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/MotoresSumergiblesPag"
                style={{
                  display: motoresExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Motores Sumergibles
              </NavDropdown.Item>
              <Dropdown.Toggle
                style={{
                  color: "#fff",
                  fontFamily: "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  width: "100%",
                  borderRadius: "0px",
                  borderColor: "#fafafa",
                }}
                onClick={handleTubosToggle}
              >
                Tubos
              </Dropdown.Toggle>
              <NavDropdown.Item
                as={Link}
                to="/TubosColumnasdeAceroalCarbonoPag"
                style={{
                  display: tubosExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Columnas de Acero al Carbono
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/TubosColumnasdeUPVCPag"
                style={{
                  display: tubosExpanded ? "block" : "none",
                  color: "#fff",
                  fontFamily:
                    "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
                  backgroundColor: "#2D4E76",
                  whiteSpace: "normal",
                  paddingLeft: "30px",
                }}
              >
                Columnas de UPVC
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              as={Link}
              to="/contacto"
              style={{
                color: "#fff",
                fontFamily:
                  "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
              }}
            >
              Contacto
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="http://odoo.motobombas.com/"
              style={{
                color: "#fff",
                fontFamily:
                  "'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif",
              }}
            >
              Login
            </Nav.Link>
            <Form className="d-flex" onSubmit={handleSearch}>
              <Form.Control
                type="search"
                name="search"
                placeholder="Buscar"
                className="me-2"
                aria-label="Search"
              />
              <Button
                type="submit"
                variant="submit" // Cambia a "submit" para que el botón active la función onSubmit
                style={{
                  backgroundColor: "#2D4E76",
                  color: "#FFFFFF",
                  borderColor: "#FFFFFF",
                }}
              >
                Buscar
              </Button>
            </Form>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{ marginTop: "0px" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Layout;